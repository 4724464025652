import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import isologotipoLogin from "./assets/svgs/isologotipoLogin.svg";

const AuthContent = ({ children }: { children: any }) => {
  const { route } = useAuthenticator((context) => [context.route]);

  if (route !== "authenticated") {
    return (
      <div className="flex flex-column align-items-center justify-content-center min-h-screen">
        <div className="flex flex-column align-items-center">
          <img
            src={isologotipoLogin}
            alt="Logo"
            style={{ position: "relative", top: "30px" }}
          />
          <span className="font-medium">
            Inicia sesión con tu correo electrónico o contraseña
          </span>
        </div>
        <Authenticator hideSignUp={true} className="custom-authenticator" />
      </div>
    );
  }

  return children;
};

const CustomAuthenticator = ({ children }: { children: any }) => (
  <Authenticator.Provider>
    <AuthContent>{children}</AuthContent>
  </Authenticator.Provider>
);

export default CustomAuthenticator;
