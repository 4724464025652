import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { yupConstants } from "../../utils/constants/yup";
import { NewSubTypeForm } from "../../models/forms";
import { EventSubType } from "../../models/events";
import toast from "react-hot-toast";
import {
  useGetEventTypesQuery,
  useNewEventSubTypeMutation,
  useUpdateEventSubTypeMutation,
} from "../../store/services/events-types";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

interface SubTypeFormProps {
  subTypeData?: EventSubType;
  handleClose?: () => void;
}

export const SubTypeForm = ({
  subTypeData,
  handleClose = () => {},
}: SubTypeFormProps) => {
  const { data: dataEventsTypes, isLoading: isLoadingTypes } =
    useGetEventTypesQuery();

  const [newEventSubType, { isLoading: isLoadingNew }] =
    useNewEventSubTypeMutation();
  const [updateEventSubType, { isLoading: isLoadingUpdate }] =
    useUpdateEventSubTypeMutation();
  const { handleSubmit, touched, errors, values, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        eventSubTypeId: subTypeData?.eventSubTypeId || "",
        eventSubTypeName: subTypeData?.eventSubTypeName || "",
        eventTypeId: subTypeData?.eventTypeId || "",
      } as NewSubTypeForm,
      validationSchema: Yup.object({
        eventSubTypeName: Yup.string().required(yupConstants().required),
        eventTypeId: Yup.string().required(yupConstants().required),
      }),
      onSubmit: ({ eventTypeId, eventSubTypeId, eventSubTypeName }) => {
        if (!!subTypeData) {
          updateEventSubType({
            eventSubTypeName,
            eventTypeId: parseInt(eventTypeId),
            eventSubTypeId: parseInt(eventSubTypeId),
          })
            .unwrap()
            .then(() => {
              toast.success("sub tipo modificado exitosamente!");
              handleClose();
            })
            .catch(() => toast.error("No se pudo modificar el sub tipo"));
        } else {
          newEventSubType({
            eventSubTypeName,
            eventTypeId: parseInt(eventTypeId),
          })
            .unwrap()
            .then(() => {
              toast.success("sub tipo creado exitosamente!");
              handleClose();
            })
            .catch(() => toast.error("No se pudo crear el sub tipo"));
        }
      },
    });
  return (
    <form className="grid" onSubmit={handleSubmit}>
      <div className="col my-2">
        <span className="p-float-label">
          <Dropdown
            invalid={!!touched.eventTypeId && !!errors.eventTypeId}
            className="w-full"
            id="eventTypeId"
            name="eventTypeId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.eventTypeId}
            optionLabel="eventTypeName"
            optionValue="eventTypeId"
            options={dataEventsTypes}
            loading={isLoadingTypes}
            disabled={isLoadingNew || isLoadingUpdate}
          />
          <label htmlFor="eventTypeId">Tipo</label>
        </span>
        {touched.eventTypeId && errors.eventTypeId && (
          <small className="p-error">{errors.eventTypeId}</small>
        )}
      </div>
      <div className="col-12 my-2">
        <span className="p-float-label">
          <InputText
            invalid={!!touched.eventSubTypeName && !!errors.eventSubTypeName}
            className="w-full"
            id="eventSubTypeName"
            name="eventSubTypeName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.eventSubTypeName}
            disabled={isLoadingNew || isLoadingUpdate}
          />
          <label htmlFor="name">Nombre de Sub-Tipo</label>
        </span>
        {touched.eventSubTypeName && errors.eventSubTypeName && (
          <small className="p-error">{errors.eventSubTypeName}</small>
        )}
      </div>

      <div className="col-12 flex justify-content-center gap-4">
        <Button
          icon="pi pi-check"
          severity="success"
          type="submit"
          label={"Envíar"}
          loading={isLoadingNew || isLoadingUpdate}
        />
        <Button
          icon="pi pi-times"
          severity="secondary"
          type="button"
          label={"Cancelar"}
          onClick={handleClose}
          loading={isLoadingNew || isLoadingUpdate}
        />
      </div>
    </form>
  );
};
