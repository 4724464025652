import { useState } from "react";
import { SidebarNav } from "./SidebarNav";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import isologotipo  from '../../assets/svgs/isologotipo.svg'

export const Header = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const handleSidebar = () => setShowSidebar((prev) => !prev);
  return (
    <div className="flex justify-content-between align-content-center bg-primary pl-4">
      <Link to="/" className="no-underline text-white">
        <img src={isologotipo} alt="isologotipo" className="w-6" style={{
          position: 'relative',
          right: '40px'
        }}/>
      </Link>
      <SidebarNav show={showSidebar} handleShow={handleSidebar} />
      <Button icon="pi pi-bars" onClick={handleSidebar} />
    </div>
  );
};
