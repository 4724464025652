import { Event } from "../../models/events";
import { daysOfWeek, months } from "../constants/date-constants";

export const formatDate = (date?: Date | string) => {
  const transform = new Date(date || "").toLocaleString();

  return transform;
};

export const formatDateWithoutSeconds = (date?: Date | string) => {
  const transform = new Date(date || "").toLocaleString("default", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  });

  return transform;
};

export const formatFriendlyDate = (dateString: Date | string) => {
  const date = new Date(dateString);

  const dayOfWeek = daysOfWeek[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${formattedHours}:${formattedMinutes} ${ampm}\n${dayOfWeek}, ${month} ${day}`;
};

export const parseDate = (dateString: string) => {
  const parsedDate = new Date(dateString);
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

export const extractHoursAndMinutes = (value: string | undefined) => {
  const durationValue = value ? parseDate(value) : null;
  const hours = durationValue?.getHours().toString().padStart(2, "0");
  const minutes = durationValue?.getMinutes().toString().padStart(2, "0");
  const date = durationValue ? `${hours}:${minutes}` : null;

  return date;
};

export const correctDateAndHours = (data: Event) => {
  const eventDate = new Date(data?.startDate);
  const eventDuration = new Date(data?.duration);

  const eventHours = eventDate.getHours();
  const eventMinutes = eventDate.getMinutes();

  const durationHours = eventDuration.getHours();
  const durationMinutes = eventDuration.getMinutes();

  eventDate.setHours(eventHours + durationHours);
  eventDate.setMinutes(eventMinutes + durationMinutes);

  return eventDate;
};
