import {
  EventSubType,
  EventType,
  NewEventSubType,
  UpdateEventSubType,
} from "../../models/events";
import { api } from "./api";

export const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEventTypes: build.query<EventType[], void>({
      query: () => ({
        url: "/events/types",
      }),
    }),
    getEventSubTypes: build.query<EventSubType[], void>({
      query: () => ({
        url: "/events/types/sub-types",
      }),
    }),
    newEventSubType: build.mutation<void, NewEventSubType>({
      query: (data) => ({
        url: "/events/types",
        method: "POST",
        body: data,
      }),
    }),
    updateEventSubType: build.mutation<void, UpdateEventSubType>({
      query: (data) => ({
        url: "/events/types",
        method: "PATCH",
        body: data,
      }),
    }),
    deleteEventSubType: build.mutation<void, string | number>({
      query: (id) => ({
        url: `/events/types/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetEventTypesQuery,
  useNewEventSubTypeMutation,
  useUpdateEventSubTypeMutation,
  useDeleteEventSubTypeMutation,
  useGetEventSubTypesQuery,
} = eventsApi;
