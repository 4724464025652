import React from "react";
import { EventListDV } from "../components";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

export const UpcomingEvents = () => {
  const navigate = useNavigate();
  return (
    <div className="p-card gap-4  p-3">
      <div className="flex w-full justify-content-center align-items-center gap-3 mt-4 mb-6">
        <h1 className="text-center">Próximos eventos </h1>
        <Button
          icon="pi pi-plus"
          rounded
          aria-label="Plus"
          size="small"
          onClick={() => navigate("/events/new")}
        />
      </div>
      <EventListDV show="next" />
    </div>
  );
};
