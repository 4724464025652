import { useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { useGetUserByIdQuery } from "../../store/services/users";
import { UserForm } from "../../components/Forms/UserForm";

export const EditUser = () => {
  const { userId } = useParams();
  const { data, isLoading } = useGetUserByIdQuery(userId || "");

  return (
    <div className="p-card gap-4  p-3">
      <h1 className="text-center text-3xl">Editar Usuario Ficticio</h1>
      {isLoading ? (
        <div className="gap-4 flex flex-column justify-content-center align-items-center p-3 min-h-11rem p-8">
          <h1 className="text-center text-3xl font-bold mb-6">
            Cargando contenido
          </h1>
          <ProgressSpinner />
        </div>
      ) : (
        <UserForm userData={data} />
      )}
    </div>
  );
};
