import React, { useState } from "react";

import { DataView } from "primereact/dataview";
import { EventMessage } from "../../models/events";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { EventMessageForm } from "../Forms/EventMessageForm";
import toast from "react-hot-toast";
import {
  useDeleteEventMessageMutation,
  useGetEventMessagesQuery,
} from "../../store/services/events-messages";

interface EventMessagesDVProps {
  eventId: number;
  validateDate?: boolean;
}

export const EventMessagesDV = ({
  eventId,
  validateDate,
}: EventMessagesDVProps) => {
  const [newEventMessage, setNewEventMessage] = useState<boolean>(false);
  const [editEventMessage, setEditEventMessage] = useState<EventMessage>();
  const [messageToDelete, setMessageToDelete] = useState<EventMessage>();

  const hideNewEventMessageDialog = () => {
    refetchData();
    setNewEventMessage(false);
  };

  const hideEditEventMessageDialog = () => {
    refetchData();
    setEditEventMessage(undefined);
  };
  const hideDeleteEventMessageDialog = () => {
    refetchData();
    setMessageToDelete(undefined);
  };

  const [deleteEventMessage, { isLoading: isLoadingDelete }] =
    useDeleteEventMessageMutation();

  const handleDelete = () => {
    deleteEventMessage(messageToDelete?.id || 0)
      .unwrap()
      .then(() => {
        toast.success("Mensaje eliminado exitosamente!");
        hideDeleteEventMessageDialog();
      })
      .catch(() => toast.error("No se pudo eliminar el mensaje"));
  };

  const {
    data: messagesData,
    isLoading: isLoadingData,
    refetch: refetchData,
  } = useGetEventMessagesQuery(eventId);

  return (
    <>
      <DataView
        header={
          <div className="flex align-items-center gap-2">
            <h1 className="text-3xl">Mensajes del evento</h1>
            {!validateDate && (
              <Button
                icon="pi pi-plus"
                rounded
                size="small"
                onClick={() => setNewEventMessage(true)}
              />
            )}
          </div>
        }
        loading={isLoadingData}
        value={messagesData || []}
        paginator
        rows={5}
        emptyMessage="Sin mensajes"
        listTemplate={(messages: EventMessage[]): React.ReactNode[] =>
          messages.map((msg) => (
            <div
              className="p-3  border-bottom-1 surface-border"
              key={msg.message}
            >
              <div className="flex justify-content-between align-items-start">
                <h1 className="text-3xl font-medium text-900  flex align-items-center gap-2 p-0 m-0">
                  {msg.title}
                </h1>
                <div className="flex gap-2">
                  <Button
                    icon="pi pi-pencil"
                    severity="success"
                    tooltip="Editar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => setEditEventMessage(msg)}
                    disabled={validateDate}
                  />
                  <Button
                    icon="pi pi-trash"
                    severity="danger"
                    tooltip="Eliminar"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => setMessageToDelete(msg)}
                    disabled={validateDate}
                  />
                </div>
              </div>
              <p className="text-sm text-400 font-semibold m-0 p-0 mb-3">
                Enviado: {new Date(msg.createDate).toLocaleString()}
              </p>
              <p className="line-height-3 text-600 text-lg  p-0 m-0">
                {msg.message}
              </p>
            </div>
          ))
        }
      />
      {newEventMessage && (
        <Dialog
          header="Nuevo mensaje para el evento"
          visible={!!newEventMessage}
          style={{ width: "40vw" }}
          onHide={hideNewEventMessageDialog}
        >
          <EventMessageForm
            eventId={eventId}
            userId={1}
            handleClose={hideNewEventMessageDialog}
          />
        </Dialog>
      )}
      {editEventMessage && (
        <Dialog
          header="Editar mensaje del evento"
          visible={!!editEventMessage}
          style={{ width: "40vw" }}
          onHide={hideEditEventMessageDialog}
        >
          <EventMessageForm
            eventId={eventId}
            userId={1}
            messageId={editEventMessage.id}
            title={editEventMessage.title}
            message={editEventMessage.message}
            handleClose={hideEditEventMessageDialog}
          />
        </Dialog>
      )}
      {messageToDelete && (
        <Dialog
          header="Eliminar mensaje del evento"
          visible={!!messageToDelete}
          style={{ width: "60vw" }}
          onHide={hideDeleteEventMessageDialog}
          footer={
            <div className="col-12 flex justify-content-center gap-4">
              <Button
                icon="pi pi-trash"
                severity="danger"
                type="submit"
                label={"Eliminar"}
                onClick={handleDelete}
                loading={isLoadingDelete}
              />
              <Button
                icon="pi pi-times"
                severity="secondary"
                type="button"
                label={"Cancelar"}
                onClick={hideDeleteEventMessageDialog}
                loading={isLoadingDelete}
              />
            </div>
          }
        >
          <h1 className="text-3xl text-red-500 m-0 p-0">
            <i className="pi pi-exclamation-triangle"></i> ¡Cuidado!
          </h1>
          <p className="m-0 p-0 mb-4">
            ¿Estas seguro que desea eliminar el mensaje?
          </p>
          <div className="border-1 border-round border-400 p-1">
            <h1 className="text-3xl font-medium text-900  flex align-items-center gap-2 p-0 m-0">
              {messageToDelete.title}
            </h1>
            <p className="line-height-3 text-600 text-lg  p-0 m-0">
              {messageToDelete.message}
            </p>
          </div>
        </Dialog>
      )}
    </>
  );
};
