import React from "react";
import { EventTypesDV } from "../../components";

export const EventTypes = () => {
  return (
    <div className="p-card">
      <EventTypesDV />
    </div>
  );
};
