import { EventUsers } from "../../models/events";

export const PhoneBodyTemplate = (user: EventUsers) => {
  if (!user.phoneCode || !user.phone) {
    return <div>No disponible</div>;
  }

  return (
    <div>
      <b>({user.phoneCode})</b> {user.phone}
    </div>
  );
};
