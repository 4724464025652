import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { fetchAuthSession, signOut } from "aws-amplify/auth";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  prepareHeaders: async (headers) => {
    const session = await fetchAuthSession();
    const accessToken = session.tokens?.accessToken?.toString() ?? "";
    const idToken = session.tokens?.idToken?.toString() ?? "";
    const sessionRole =
      (session?.tokens?.accessToken?.payload[
        process.env.REACT_APP_COGNITO_GROUPS || ""
      ] as Array<string>) || [];
    if (
      !accessToken ||
      !idToken ||
      !!!sessionRole.find(
        (value) => value === process.env.REACT_APP_COGNITO_ROLE
      )
    ) {
      signOut();
    }

    if (!!accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "ebAPI",
  baseQuery: baseQuery,
  refetchOnMountOrArgChange: true,
  refetchOnFocus: true,
  refetchOnReconnect: true,

  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: ["events,users"],
  endpoints: () => ({}),
});
