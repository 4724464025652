import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import React, { useState } from "react";
import {
  useDeleteEventSubTypeMutation,
  useGetEventSubTypesQuery,
} from "../../store/services/events-types";
import { EventSubType } from "../../models/events";
import { formatDate } from "../../utils/functions/date";
import { Dialog } from "primereact/dialog";
import { SubTypeForm } from "../Forms/SubTypeForm";
import toast from "react-hot-toast";

export const EventTypesDV = () => {
  const [newSubType, setNewSubType] = useState<boolean>();
  const [updateSubType, setUpdateSubType] = useState<EventSubType>();
  const [deleteSubType, setdeleteSubType] = useState<EventSubType>();

  const {
    data: dataEventsSubTypes,
    isLoading: isLoadingSubTypes,
    refetch: refetchSubTypes,
  } = useGetEventSubTypesQuery();

  const [deleteEventSubType, { isLoading: isLoadingDelete }] =
    useDeleteEventSubTypeMutation();

  const hideNewSubType = () => {
    refetchSubTypes();
    setNewSubType(false);
  };

  const hideUpdateSubType = () => {
    refetchSubTypes();
    setUpdateSubType(undefined);
  };

  const hideDeleteSubType = () => {
    refetchSubTypes();
    setdeleteSubType(undefined);
  };

  const handleDelete = () => {
    deleteEventSubType(deleteSubType?.eventSubTypeId || 0)
      .unwrap()
      .then(() => {
        toast.success("Sub-tipo eliminado exitosamente!");
        hideDeleteSubType();
      })
      .catch(() => toast.error("No se pudo eliminar el mensaje"));
  };

  return (
    <>
      <DataView
        header={
          <div className="flex w-full justify-content-center align-items-center gap-3 ">
            <h1 className="text-center">Gestión de sub-tipos</h1>
            <Button
              icon="pi pi-plus"
              rounded
              aria-label="Plus"
              size="small"
              onClick={() => setNewSubType(true)}
            />
          </div>
        }
        loading={isLoadingSubTypes}
        value={dataEventsSubTypes || []}
        paginator
        rows={5}
        emptyMessage="Sin sub-tipos"
        listTemplate={(messages: EventSubType[]): React.ReactNode[] =>
          messages.map((subType) => (
            <div
              className="p-3 border-bottom-1 surface-border"
              key={subType.eventSubTypeId}
            >
              <div className="flex justify-content-between align-items-start">
                <div>
                  <h1 className="text-xl font-medium text-900  flex align-items-center gap-2 p-0 m-0">
                    Tipo: {subType.eventTypeName}
                  </h1>
                  <h1 className="text-xl font-medium text-900  flex align-items-center gap-2 p-0 m-0">
                    Sub-Tipo: {subType.eventSubTypeName}
                  </h1>
                </div>

                <div className="flex gap-2">
                  <Button
                    icon="pi pi-pencil"
                    severity="success"
                    onClick={() => setUpdateSubType(subType)}
                  />
                  <Button
                    icon="pi pi-trash"
                    severity="danger"
                    onClick={() => setdeleteSubType(subType)}
                  />
                </div>
              </div>
              <p className="text-sm text-400 font-semibold m-0 p-0 mb-3">
                Creado: {formatDate(subType.createDate)}
              </p>
              {/* <p className="line-height-3 text-600 text-lg  p-0 m-0">
          {subType.message}
        </p> */}
            </div>
          ))
        }
      />
      {newSubType && (
        <Dialog
          header="Nuevo sub-tipo"
          visible={!!newSubType}
          style={{ width: "40vw" }}
          onHide={() => setNewSubType(false)}
        >
          <SubTypeForm handleClose={hideNewSubType} />
        </Dialog>
      )}
      {updateSubType && (
        <Dialog
          header="Editar sub-tipo"
          visible={!!updateSubType}
          style={{ width: "40vw" }}
          onHide={() => setNewSubType(false)}
        >
          <SubTypeForm
            subTypeData={updateSubType}
            handleClose={hideUpdateSubType}
          />
        </Dialog>
      )}
      {deleteSubType && (
        <Dialog
          header="Eliminar sub tipo"
          visible={!!deleteSubType}
          style={{ width: "60vw" }}
          onHide={hideDeleteSubType}
          footer={
            <div className="col-12 flex justify-content-center gap-4">
              <Button
                icon="pi pi-trash"
                severity="danger"
                type="submit"
                label={"Eliminar"}
                onClick={handleDelete}
                loading={isLoadingDelete}
              />
              <Button
                icon="pi pi-times"
                severity="secondary"
                type="button"
                label={"Cancelar"}
                onClick={hideDeleteSubType}
                loading={isLoadingDelete}
              />
            </div>
          }
        >
          <h1 className="text-3xl text-red-500 m-0 p-0">
            <i className="pi pi-exclamation-triangle"></i> ¡Cuidado!
          </h1>
          <p className="m-0 p-0 mb-4">
            ¿Estas seguro que desea eliminar el sub tipo:{" "}
            {deleteSubType.eventSubTypeName}?
          </p>
        </Dialog>
      )}
    </>
  );
};
