import { Button } from "primereact/button";
import {
  DataTable,
  DataTableSelectionSingleChangeEvent,
  DataTableValueArray,
} from "primereact/datatable";
import React, { useState } from "react";
import { EventUsers } from "../../models/events";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import {
  useBanEventUsersMutation,
  useDeleteEventUsersMutation,
  useGetEventUsersQuery,
  useSavePaymentsMutation,
  useUnsavePaymentsMutation,
} from "../../store/services/events";
import toast from "react-hot-toast";
import { Dialog } from "primereact/dialog";
import { SelectUsers } from "../SelectUsers";
import { sortUsersByPaidStatus } from "../../utils/functions/users";
import { PhoneBodyTemplate } from "../TableComponents/PhoneNumberTemplate";
import { PaymentBodyTemplate } from "../TableComponents/PaymentTemplate";

interface InscriptionsDTProps {
  eventId: number;
  mode: "user" | "fictional";
  handleClose: () => void;
  validateDate?: boolean;
}

export const InscriptionsDT = ({
  eventId,
  mode,
  handleClose,
  validateDate,
}: InscriptionsDTProps) => {
  const [realUsersToAdd, setRealUsersToAdd] = useState<number>();
  const [fictionalUsersToAdd, setFictionalUsersToAdd] = useState<number>();
  const [usersSelected, setUsersSelected] = useState<EventUsers[]>([]);
  const userType = mode === "user" ? 2 : 3;

  const {
    data: eventUsers,
    isLoading: isLoadingEventUsers,
    refetch: refetchEventUsers,
  } = useGetEventUsersQuery(eventId);

  const [banEventUsers, { isLoading: isLoadingBan }] =
    useBanEventUsersMutation();

  const [deleteEventUsers, { isLoading: isLoadingDelete }] =
    useDeleteEventUsersMutation();

  const [saveUsersPayment, { isLoading: isLoadingPayment }] =
    useSavePaymentsMutation();

  const [unsaveUsersPayment, { isLoading: isLoadingUnsavePaymenty }] =
    useUnsavePaymentsMutation();

  const hideAddRealUsersDialog = () => {
    refetchEventUsers();
    handleClose();
    setRealUsersToAdd(undefined);
  };
  const hideAddFictionalUsersDialog = () => {
    refetchEventUsers();
    handleClose();
    setFictionalUsersToAdd(undefined);
  };

  const handleBan = () => {
    banEventUsers({
      id: eventId,
      usersIds: [...usersSelected.map((user) => user.userId)],
    })
      .unwrap()
      .then(() => {
        toast.success("Usuarios baneados del evento exitosamente!");
      })
      .catch(() => toast.error("No se pudieron banear los usuarios al evento"))
      .finally(() => {
        refetchEventUsers();
        handleClose();
        setUsersSelected([]);
      });
  };

  const handleDelete = () => {
    deleteEventUsers({
      id: eventId,
      usersIds: [...usersSelected.map((user) => user.userId)],
    })
      .unwrap()
      .then(() => {
        toast.success("Usuarios eliminados del evento exitosamente!");
      })
      .catch(() =>
        toast.error("No se pudieron eliminar los usuarios al evento")
      )
      .finally(() => {
        refetchEventUsers();
        handleClose();
        setUsersSelected([]);
      });
  };

  const handlePayment = () => {
    saveUsersPayment({
      id: eventId,
      usersIds: [...usersSelected.map((user) => user.userId)],
    })
      .unwrap()
      .then(() => {
        toast.success("Los pagos se han guardado correctamente!");
      })
      .catch(() => toast.error("No se pudieron guardar los pagos"))
      .finally(() => {
        refetchEventUsers();
        handleClose();
        setUsersSelected([]);
      });
  };

  const handleUnsavePayment = () => {
    unsaveUsersPayment({
      id: eventId,
      usersIds: [...usersSelected.map((user) => user.userId)],
    })
      .unwrap()
      .then(() => {
        toast.success("Los pagos se han guardado correctamente!");
      })
      .catch(() => toast.error("No se pudieron guardar los pagos"))
      .finally(() => {
        refetchEventUsers();
        handleClose();
        setUsersSelected([]);
      });
  };

  const rowClassName = (user: EventUsers) => {
    return {
      "bg-green-100": user.paid,
    };
  };

  return (
    <>
      <DataTable
        value={sortUsersByPaidStatus(
          eventUsers?.filter((item) => item.userTypeId === userType)
        )}
        loading={
          isLoadingBan ||
          isLoadingDelete ||
          isLoadingEventUsers ||
          isLoadingPayment ||
          isLoadingUnsavePaymenty
        }
        header={() => (
          <div className="flex flex-column md:flex-row justify-content-between align-items-center">
            <div className="flex align-items-center gap-2">
              <h1 className="text-2xl font-bold m-0 p-0">
                Lista de usuarios {mode === "user" ? "reales" : "ficticios"}
              </h1>
              {!validateDate && (
                <Button
                  icon="pi pi-plus"
                  rounded
                  size="small"
                  onClick={() =>
                    mode === "user"
                      ? setRealUsersToAdd(eventId)
                      : setFictionalUsersToAdd(eventId)
                  }
                />
              )}
            </div>
            <div className="flex gap-2 flex-wrap mt-4 md:flex-nowrap md:mt-0">
              {mode === "user" && (
                <Button
                  disabled={!usersSelected.length}
                  outlined
                  icon="pi pi-ban"
                  severity="warning"
                  size="small"
                  label="Banear"
                  onClick={handleBan}
                />
              )}
              <Button
                disabled={!usersSelected.length}
                outlined
                icon="pi pi-user-minus"
                severity="danger"
                size="small"
                label="Eliminar"
                onClick={handleDelete}
              />
              {mode === "user" && (
                <Button
                  disabled={!usersSelected.length}
                  outlined
                  icon="pi pi-money-bill"
                  severity="success"
                  size="small"
                  label="Pagar"
                  onClick={handlePayment}
                />
              )}
              {mode === "user" && (
                <Button
                  disabled={!usersSelected.length}
                  outlined
                  icon="pi pi-money-bill"
                  severity="danger"
                  size="small"
                  label="Deshacer pago"
                  onClick={handleUnsavePayment}
                />
              )}
            </div>
          </div>
        )}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
        rowClassName={rowClassName}
        className="my-4"
        filterDisplay="row"
        globalFilterFields={[
          "userEmail",
          "userLastName",
          "userFirstName",
          "phone",
        ]}
        selection={usersSelected}
        onSelectionChange={({
          value,
        }: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
          setUsersSelected(value as EventUsers[]);
        }}
      >
        {!validateDate && (
          <Column selectionMode="multiple" headerStyle={{ width: "3rem" }} />
        )}

        <Column
          field="userEmail"
          header="Email"
          filter
          filterPlaceholder="Buscar por email"
          showFilterMenu={false}
          filterMatchMode="contains"
          body={(user: EventUsers) => (
            <div className="flex align-items-center gap-2">
              {user.userEmail} <Tooltip target=".custom-target-icon-ban" />
            </div>
          )}
        />
        <Column
          field="userLastName"
          header="Apellido"
          filter
          filterPlaceholder="Buscar por apellido"
          showFilterMenu={false}
          filterMatchMode="contains"
        />
        <Column
          field="userFirstName"
          header="Nombre"
          filter
          filterPlaceholder="Buscar por nombre"
          showFilterMenu={false}
          filterMatchMode="contains"
        />
        {mode === "user" && (
          <Column
            body={PhoneBodyTemplate}
            header="Teléfono"
            field="phone"
            filter
            showFilterMenu={false}
            filterPlaceholder="Buscar por telefono"
            filterMatchMode="contains"
          ></Column>
        )}
        {mode === "user" && (
          <Column
            field="guests"
            header="Invitados"
            headerStyle={{ width: "9rem" }}
          />
        )}
        {mode === "user" && (
          <Column
            field="Pagos"
            header="Pagos"
            headerStyle={{ width: "9rem" }}
            body={PaymentBodyTemplate}
          />
        )}
      </DataTable>
      {realUsersToAdd && (
        <Dialog
          header="Agregar usuarios reales"
          visible={!!realUsersToAdd}
          style={{ width: "90vw" }}
          onHide={hideAddRealUsersDialog}
        >
          <SelectUsers
            mode="user"
            eventId={realUsersToAdd}
            handleClose={hideAddRealUsersDialog}
          />
        </Dialog>
      )}
      {fictionalUsersToAdd && (
        <Dialog
          header="Agregar usuarios ficticios"
          visible={!!fictionalUsersToAdd}
          style={{ width: "90vw" }}
          onHide={hideAddFictionalUsersDialog}
        >
          <SelectUsers
            mode="fictional"
            eventId={fictionalUsersToAdd}
            handleClose={hideAddFictionalUsersDialog}
          />
        </Dialog>
      )}
    </>
  );
};
