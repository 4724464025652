export const usersTypes = [
  { name: "Administrador", value: "admin" },
  { name: "User app", value: "app" },
];

export const spacesTypes = [
  { name: "Abierto", value: "Abierto" },
  { name: "Cerrado", value: "Cerrado" },
];

export const guestsTypes = [
  { name: "Sin invitados", value: "0" },
  { name: 1, value: "1" },
  { name: 2, value: "2" },
  { name: 3, value: "3" },
];

export const limitsTypes = [
  { name: 10, value: 10 },
  { name: 40, value: 40 },
  { name: 80, value: 80 },
];
