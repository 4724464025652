import { EventForm } from "../../components";

export const NewEvent = () => {
  return (
    <div className="p-card gap-4  p-3  align-self-center">
      <h1 className="text-center text-3xl">Crear Evento</h1>
      <EventForm />
    </div>
  );
};
