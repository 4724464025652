/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import {
  useChangeEventStateMutation,
  useDeleteEventMutation,
  useGetEventByIdQuery,
} from "../../store/services/events";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "primereact/badge";
import {
  correctDateAndHours,
  extractHoursAndMinutes,
  formatDateWithoutSeconds,
  formatFriendlyDate,
} from "../../utils/functions/date";
import { ProgressSpinner } from "primereact/progressspinner";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
  DuplicateEventForm,
  EventMessagesDV,
  InscriptionsDT,
} from "../../components";
import { Image } from "primereact/image";
import { SingleSelectUser } from "../../components/SingleSelectUser";
import toast from "react-hot-toast";
import { Event } from "../../models/events";

export const EventDetail = () => {
  const { eventId } = useParams();
  const [hostToAdd, setHostToAdd] = useState<number>();
  const [duplicateEvent, setDuplicateEvent] = useState<Event>();
  const [eventToDelete, setEventToDelete] = useState<Event>();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    refetch: refetchEventDetail,
  } = useGetEventByIdQuery(eventId || "");

  const [deleteEvent] = useDeleteEventMutation();

  const [changeEventState] = useChangeEventStateMutation();

  const hideAddHostDialog = () => setHostToAdd(undefined);
  const hideDuplicateEventDialog = () => setDuplicateEvent(undefined);
  const hideDeleteEventDialog = () => setEventToDelete(undefined);
  const validateDate = data && correctDateAndHours(data) < new Date();

  const handleChangeEventState = (id: number | undefined) => {
    if (id)
      changeEventState(id)
        .unwrap()
        .then(() => {
          toast.success("Estado del evento cambiado exitosamente!");
          refetchEventDetail();
        })
        .catch(() => toast.error("No se pudo cambiar el estado del evento"));
  };

  const handleDeleteEvent = (id: number) => {
    deleteEvent(id)
      .unwrap()
      .then(() => {
        toast.success("Evento eliminado exitosamente!");
        navigate(-1);
      })
      .catch(() => toast.error("No se pudo eliminar el evento"))
      .finally(() => hideDeleteEventDialog());
  };

  if (isLoading)
    return (
      <div className="p-card gap-4 flex flex-column justify-content-center align-items-center p-3 min-h-11rem p-8">
        <h1 className="text-center text-3xl font-bold mb-6">
          Detalle del evento
        </h1>
        <ProgressSpinner />
      </div>
    );

  return (
    <div className="p-card gap-4  p-3">
      <div className="flex w-full gap-4 justify-content-end">
        <Button
          icon="pi pi-pencil"
          severity="success"
          onClick={() => navigate(`/events/${data?.id}/edit`)}
          disabled={validateDate}
          tooltip="Editar"
          tooltipOptions={{ position: "left" }}
        />
        <Button
          icon="pi pi-clone"
          severity="warning"
          onClick={() => setDuplicateEvent(data as Event | undefined)}
          tooltip="Duplicar"
          tooltipOptions={{ position: "left" }}
        />
        <Button
          icon="pi pi-eye-slash"
          severity="danger"
          onClick={() => handleChangeEventState(data?.id)}
          disabled={validateDate}
          tooltip="Activar/Desactivar"
          tooltipOptions={{ position: "left" }}
        />
        <Button
          icon="pi pi-times"
          severity="danger"
          onClick={() => setEventToDelete(data)}
          tooltip="Eliminar evento"
          tooltipOptions={{ position: "left" }}
        />
      </div>
      <h1 className="text-center text-3xl font-bold mb-3">{data?.name}</h1>
      <div className="grid">
        <div className="col-6 md:col-4 md:my-3 flex flex-column align-items-center">
          <Badge
            className="md:hidden"
            value={
              data && correctDateAndHours(data) < new Date()
                ? "Finalizado"
                : data?.active
                ? "Estado: Activo"
                : "Estado: Inactivo"
            }
            severity={
              data && correctDateAndHours(data) < new Date()
                ? "warning"
                : data?.active
                ? "success"
                : "danger"
            }
          />
          <Badge
            className="hidden md:block"
            value={
              data && correctDateAndHours(data) < new Date()
                ? "Finalizado"
                : data?.active
                ? "Estado: Activo"
                : "Estado: Inactivo"
            }
            size={"xlarge"}
            severity={
              data && correctDateAndHours(data) < new Date()
                ? "warning"
                : data?.active
                ? "success"
                : "danger"
            }
          />
        </div>
        <div className="col-6 md:col-4 md:my-3 flex flex-column align-items-center">
          <Badge className="md:hidden" value={`Cupos: ${data?.amount}`} />
          <Badge
            className="hidden md:block"
            value={`Cupos: ${data?.amount}`}
            size={"xlarge"}
          />
        </div>
        <div className="col-12 md:col-4 md:my-3 flex flex-column align-items-center">
          <Badge
            className="md:hidden"
            value={`Disponibles: ${data?.available}`}
            severity={"warning"}
          />
          <Badge
            className="hidden md:block"
            value={`Disponibles: ${data?.available}`}
            size={"xlarge"}
            severity={"warning"}
          />
        </div>
        <div className="col-12 md:col-6 grid">
          <div className="col-12 mb-2">
            {data?.hostLastName && data?.hostFirstName && (
              <>
                <div className="flex align-items-end mb-2 gap-4 mt-4">
                  <div>
                    <Image
                      src={`${
                        data?.hostImageBase64
                          ? data?.hostImageFormat + "," + data?.hostImageBase64
                          : "/assets/images/Image-not-found.png"
                      }`}
                      height="100"
                      width="auto"
                      alt={data?.name}
                    />
                  </div>
                  <div className="flex align-items-center">
                    <h2 className="text-xl font-normal m-0 p-0  underline">
                      <b>Host:</b> {data?.hostLastName}, {data?.hostFirstName}
                    </h2>
                    <Button
                      icon="pi pi-pencil"
                      severity="success"
                      rounded
                      text
                      onClick={() => setHostToAdd(parseInt(eventId || "0"))}
                    />
                  </div>
                </div>
                <span className="font-medium">
                  Descripción del host: {data?.hostDescription}
                </span>
              </>
            )}
            <span className="flex flex-wrap font-medium gap-4 lg:gap-6 mt-4">
              <span className="text-xl">
                <i className="pi pi-calendar-clock mr-2 text-xl" />
                {formatDateWithoutSeconds(data?.startDate)}
              </span>
              <span className="text-xl">
                <i className="pi pi-map-marker mr-2 text-xl" />
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${data?.address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary"
                >
                  {data?.address}
                </a>
              </span>
            </span>
            <div className="mt-4 lg:mt-2">
              <span className="font-medium text-lg mr-4">
                <i className="pi pi-tag text-lg mr-2"></i>
                Tipo: {data?.eventTypeName}
              </span>
              <span className="font-medium text-lg">
                <i className="pi pi-tag text-lg mr-2"></i>
                Sub tipo: {data?.eventSubTypeName}
              </span>
            </div>
            <div className="flex flex-wrap font-medium gap-4 mt-4">
              {data?.startDate && (
                <span className="w-15rem">
                  <i className="pi pi-calendar mr-2" />
                  {formatFriendlyDate(data.startDate)}
                </span>
              )}
              <span>
                <i className="pi pi-dollar mr-2" />
                Valor del evento: {data?.price} / Inscripcion:{" "}
                {data?.inscription}
              </span>
            </div>
            <div className="flex flex-wrap font-medium gap-4  mt-4">
              <span className="w-15rem">
                <i className="pi pi-clock mr-2" />
                Duración: {extractHoursAndMinutes(data?.duration)}hs
              </span>
              <span>
                <i className="pi pi-home mr-2" />
                Establecimiento: {data?.space}
              </span>
            </div>
            <div className="flex font-medium gap-8 mt-4">
              <span className="w-20rem">
                <i className="pi pi-users mr-2" />
                Invitados por persona: {data?.guests}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 lg:col-5 flex justify-content-center align-items-center my-2 ">
          <Image
            src={`${
              data?.imageBase64
                ? data?.imageFormat + "," + data?.imageBase64
                : "/assets/images/Image-not-found.png"
            }`}
            height="200"
            width="auto"
            alt={data?.name}
          />
          {/* <img
            className="w-9 sm:w-16rem xl:w-18rem shadow-2 block xl:block mx-auto border-round"
            src={`https://cdn.pixabay.com/photo/2018/04/26/16/39/beach-3352363_960_720.jpg`}
            alt={data?.name}
          /> */}
        </div>
      </div>
      <div className="col">
        {data?.description && (
          <h2 className="text-xl font-normal">
            Descripción: {data?.description}
          </h2>
        )}
        {data?.rules && (
          <h2 className="text-xl font-normal">Reglas: {data?.rules}</h2>
        )}
      </div>
      <InscriptionsDT
        handleClose={refetchEventDetail}
        eventId={parseInt(eventId || "0")}
        mode="user"
        validateDate={validateDate}
      />
      <InscriptionsDT
        handleClose={refetchEventDetail}
        eventId={parseInt(eventId || "0")}
        mode="fictional"
        validateDate={validateDate}
      />
      <EventMessagesDV
        eventId={parseInt(eventId || "0")}
        validateDate={validateDate}
      />
      {hostToAdd && (
        <Dialog
          header="Seleccionar host"
          visible={!!hostToAdd}
          style={{ width: "90vw" }}
          onHide={hideAddHostDialog}
        >
          {""}
          <SingleSelectUser
            eventId={hostToAdd}
            handleClose={() => {
              refetchEventDetail();
              hideAddHostDialog();
            }}
          />
        </Dialog>
      )}
      {duplicateEvent && (
        <Dialog
          header="Duplicar evento"
          visible={!!duplicateEvent}
          style={{ width: "40vw" }}
          onHide={hideDuplicateEventDialog}
        >
          <DuplicateEventForm
            event={duplicateEvent}
            handleClose={hideDuplicateEventDialog}
          />
        </Dialog>
      )}
      {eventToDelete && (
        <Dialog
          header="Eliminar evento"
          visible={!!eventToDelete}
          style={{ width: "50vw" }}
          onHide={hideDeleteEventDialog}
          footer={
            <div>
              <Button
                severity="danger"
                label="Eliminar"
                onClick={() => handleDeleteEvent(eventToDelete.id)}
              />
              <Button
                severity="secondary"
                label="Cancelar"
                onClick={hideDeleteEventDialog}
              />
            </div>
          }
        >
          <h1 className="text-3xl text-red-500 m-0 p-0">
            <i className="pi pi-exclamation-triangle"></i> ¡Cuidado!
          </h1>
          <p className="m-0 p-0">
            ¿Estas seguro que desea eliminar el evento{" "}
            <b>{eventToDelete?.name}</b>?
          </p>
        </Dialog>
      )}
    </div>
  );
};
