/* eslint-disable react-hooks/exhaustive-deps */
import { Column } from "primereact/column";
import {
  DataTable,
  DataTableSelectionSingleChangeEvent,
  DataTableValueArray,
} from "primereact/datatable";
import { useEffect, useState } from "react";
import { useGetUsersQuery } from "../store/services/users";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { User } from "../models/users";
import {
  useAddUsersEventMutation,
  useGetEventByIdQuery,
} from "../store/services/events";
import toast from "react-hot-toast";
import { Dropdown } from "primereact/dropdown";

interface SelectUsersProps {
  mode?: "user" | "fictional";
  eventId: number;
  handleClose: () => void;
}

export const SelectUsers = ({
  mode,
  eventId,
  handleClose,
}: SelectUsersProps) => {
  const [userEmail, setUserEmail] = useState<string>();
  const [userFirstName, setUserFirstName] = useState<string>();
  const [userLastName, setUserLastName] = useState<string>();
  const [usersSelected, setUsersSelected] = useState<User[]>([]);
  const [selectedGuests, setSelectedGuests] = useState(0);
 
  const {
    data: usesrData,
    isLoading,
    refetch,
  } = useGetUsersQuery({
    email: userEmail,
    firstName: userFirstName,
    lastName: userLastName,
    userTypeId: mode === "fictional" ? 3 : 2,
  });

  const {
    data: eventData,
    refetch: refetchEventData
  } = useGetEventByIdQuery(eventId);

  const [addUsersEvent, { isLoading: isLoadingUsers }] =
    useAddUsersEventMutation();

  const handleAdd = () => {
      addUsersEvent({
        id: eventId,
        usersIds: [...usersSelected.map((item) => item.id)],
        guests: selectedGuests
      })
        .unwrap()
        .then(() => {
          toast.success("Usuarios agregados al evento exitosamente!");
        })
        .catch(() =>
          toast.error("No se pudieron agregar losu usuarios al evento")
        )
        .finally(() => handleClose());
    }

  const header = (
    <div className="grid mt-4">
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="email"
            name="email"
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            value={userEmail}
          />
          <label htmlFor="email">Email</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserEmail("");
            refetch();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="lastName"
            name="lastName"
            onChange={(e) => setUserLastName(e.currentTarget.value)}
            value={userLastName}
          />
          <label htmlFor="lastName">Apellido</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserLastName("");
            refetch();
          }}
        />
      </div>
      <div className="col-12 md:col-4 p-inputgroup">
        <span className="p-float-label">
          <InputText
            className="w-full"
            id="firstName"
            name="firstName"
            onChange={(e) => setUserFirstName(e.currentTarget.value)}
            value={userFirstName}
          />
          <label htmlFor="firstName">Nombre</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={() => {
            setUserFirstName("");
            refetch();
          }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    refetchEventData();
  }, [])

  return (
    <>
      <div className="flex justify-content-between align-items-center mb-2">
        <h1 className="text-lg  m-0 p-0 ">
          Seleccione los usuarios que desea agregar al evento
        </h1>
        <Button
          icon="pi pi-plus"
          label="Agregar"
          onClick={handleAdd}
          disabled={!usersSelected.length || selectedGuests === undefined}
          loading={isLoadingUsers}
        />
      </div>
      {usersSelected.length > 0 && mode === "user" &&(
        <div className="col-6 md:col-3 my-3">
        <span className="p-float-label">
          <Dropdown
            className={`min-w-full`}
            id="guests"
            name="guests"
            onChange={(e) => setSelectedGuests(e.target.value)}
            optionLabel="name"
            emptyFilterMessage="No options available"
            emptyMessage="No guests found"
            optionValue="value"
            options={eventData ? Array.from({ length: eventData.guests + 1 }, (_, i) => ({
              name: `${i}`,
              value: i,
            })) : []}
            value={selectedGuests}
          />
          <label htmlFor="guest">Invitados por persona</label>
        </span>
      </div>
      )}
      <DataTable
        value={usesrData}
        loading={isLoading}
        header={header}
        selection={usersSelected}
        selectionMode={undefined}
        onSelectionChange={({
          value,
        }: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => {
          if (Array.isArray(value)) {
            setUsersSelected(value ? [...(value as User[])] : []);
          }
        }}
        paginator
        rows={5}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column
          selectionMode={"multiple"}
          headerStyle={{ width: "3rem" }}
        ></Column>
        <Column field="email" header="Email"></Column>
        <Column field="lastName" header="Apellido"></Column>
        <Column field="firstName" header="Nombre"></Column>
      </DataTable>
    </>
  );
};
