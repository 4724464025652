import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { yupConstants } from "../../utils/constants/yup";
import { Event } from "../../models/events";
import { useCreateEventMutation } from "../../store/services/events";
import toast from "react-hot-toast";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

interface DuplicateEventFormProps {
  event: Event;
  handleClose: () => void;
}
export const DuplicateEventForm = ({
  event,
  handleClose,
}: DuplicateEventFormProps) => {
  const [createEvent, { isLoading: isLoadingCreate }] =
    useCreateEventMutation();

  const now = new Date();
  const { handleSubmit, touched, errors, values, handleBlur, handleChange } =
    useFormik({
      initialValues: {
        startDate: now,
      },
      validationSchema: Yup.object({
        startDate: Yup.date().required(yupConstants().required),
      }),
      onSubmit: ({ startDate }) => {
        createEvent({ ...event, active: !!event.active, startDate })
          .unwrap()
          .then(() => {
            toast.success("Evento duplicado exitosamente!");
            handleClose();
          })
          .catch(() => {
            toast.error("No se pudo duplicar el evento");
            handleClose();
          });
      },
    });

  return (
    <form className="grid" onSubmit={handleSubmit}>
      <div className="col-12">
        <label htmlFor="startDate">Seleccione nueva fecha</label>
        <span className="p-label">
          <Calendar
            required
            invalid={!!touched.startDate && !!errors.startDate}
            className="w-full"
            id="startDate"
            name="startDate"
            dateFormat="dd/mm/yy"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.startDate}
            disabled={isLoadingCreate}
            showTime
            hourFormat="24"
            stepMinute={5}
            minDate={now}
            hideOnDateTimeSelect
            touchUI
          />
        </span>
        {touched.startDate && errors.startDate && (
          <small className="p-error">{yupConstants().required}</small>
        )}
      </div>
      <div className="col-12 flex justify-content-center gap-4">
        <Button
          icon="pi pi-check"
          severity="success"
          type="submit"
          label={"Duplicar"}
          loading={isLoadingCreate}
        />
        <Button
          icon="pi pi-times"
          severity="secondary"
          type="button"
          label={"Cancelar"}
          onClick={handleClose}
          loading={isLoadingCreate}
        />
      </div>
    </form>
  );
};
