import React, { SetStateAction } from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { User } from "../../models/users";

interface ActionButtonTemplateProps {
  rowData: User;
  onDelete: React.Dispatch<SetStateAction<User | undefined>>;
}

export const ActionButtonTemplate: React.FC<ActionButtonTemplateProps> = ({
  rowData,
  onDelete,
}) => {
  const navigate = useNavigate();

  if (!rowData) {
    return null;
  }

  return (
    <div className="flex justify-content-end gap-4">
      <Button
        icon="pi pi-pencil"
        tooltip="Editar"
        tooltipOptions={{ position: "left" }}
        className="p-button p-button-success"
        onClick={() => navigate(`/users/edit/${rowData.id}`)}
      />
      <Button
        icon="pi pi-trash"
        tooltip="Eliminar"
        tooltipOptions={{ position: "left" }}
        className="p-button p-button-danger"
        onClick={() => onDelete(rowData)}
      />
    </div>
  );
};
