import React from "react";
import { useParams } from "react-router-dom";
import { useGetEventByIdQuery } from "../../store/services/events";
import { ProgressSpinner } from "primereact/progressspinner";
import { EventForm } from "../../components";

export const EditEvent = () => {
  const { eventId } = useParams();
  const { data, isLoading, refetch } = useGetEventByIdQuery(eventId || "");

  return (
    <div className="p-card gap-4  p-3">
      <h1 className="text-center text-3xl">Editar Evento</h1>
      {isLoading ? (
        <div className="gap-4 flex flex-column justify-content-center align-items-center p-3 min-h-11rem p-8">
          <h1 className="text-center text-3xl font-bold mb-6">
            Cargando contenido
          </h1>
          <ProgressSpinner />
        </div>
      ) : (
        <EventForm eventData={data} refetchEventData={refetch} />
      )}
    </div>
  );
};
