import {
  NewEventMessage,
  EventMessage,
  UpdateEventMessage,
} from "../../models/events";
import { api } from "./api";

export const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    newEventMessage: build.mutation<void, NewEventMessage>({
      query: (data) => ({
        url: "/events/message",
        method: "POST",
        body: data,
      }),
    }),
    getEventMessages: build.query<EventMessage[], string | number>({
      query: (id) => ({
        url: `/events/${id}/messages`,
      }),
    }),

    updateEventMessage: build.mutation<void, UpdateEventMessage>({
      query: (data) => ({
        url: "/events/message",
        method: "PATCH",
        body: data,
      }),
    }),

    deleteEventMessage: build.mutation<void, string | number>({
      query: (id) => ({
        url: `/events/message/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useNewEventMessageMutation,
  useGetEventMessagesQuery,
  useUpdateEventMessageMutation,
  useDeleteEventMessageMutation,
} = eventsApi;
