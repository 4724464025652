import { CreateUserTypes, EditableUser, User, UsersParams } from "../../models/users";
import { api } from "./api";
export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserTypes: build.query<CreateUserTypes, void>({
      query: () => ({
        url: "/users/types",
      }),
    }),
    getUsers: build.query<User[], UsersParams>({
      query: (params) => ({
        url: "/users",
        params: params,
      }),
    }),
    getUserById: build.query<User[] | EditableUser | any, string>({
      query: (id) => ({
        url: `/users/${id}`,
      }),
    }),
    getUserByEmail: build.mutation<User[], string>({
      query: (email) => ({
        url: `/users/${email}`,
      }),
    }),
    createFictionalUser: build.mutation<void, EditableUser>({
      query: (data) => ({
        url: "/users/fictional",
        method: "POST",
        body: data,
      }),
    }),
    updateFictionalUser: build.mutation<void, EditableUser>({
      query: (data) => ({
        url: "/users/fictional",
        method: "PATCH",
        body: data,
      }),
    }),
    deleteFictionalUser: build.mutation<void, number>({
      query: (id) => ({
        url: `/users/fictional/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetUserTypesQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  useGetUserByEmailMutation,
  useCreateFictionalUserMutation,
  useUpdateFictionalUserMutation,
  useDeleteFictionalUserMutation
} = usersApi;
