/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header } from "./components";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import { fetchAuthSession, signOut } from "aws-amplify/auth";
import { ProgressSpinner } from "primereact/progressspinner";

I18n.putVocabularies(translations);
I18n.setLanguage("es");

I18n.putVocabulariesForLanguage("es", {
  Username: "Correo Electrónico",
  "Please confirm your Password": "Confirme su contraseña",
  "Enter your Username": "Escriba su correo",
});

const PageLayout = () => {
  const [isLoading, setIsLoading] = useState(true);
  async function currentSession() {
    try {
      const session = await fetchAuthSession();
      const sessionRole =
        (session?.tokens?.accessToken?.payload[
          process.env.REACT_APP_COGNITO_GROUPS || ""
        ] as Array<string>) || [];
      const accessToken = session.tokens?.accessToken?.toString() ?? "";
      const idToken = session.tokens?.idToken?.toString() ?? "";
      if (
        !accessToken ||
        !idToken ||
        !!!sessionRole.find(
          (value) => value === process.env.REACT_APP_COGNITO_ROLE
        )
      ) {
        signOut();
      }
    } catch (err) {
      console.error("Error fetching session: ", err);
      return { accessToken: "", idToken: "", loginId: "", sessionRole: [] };
    }
  }

  useLayoutEffect(() => {
    async function loadSession() {
      setIsLoading(true);
      await currentSession();
      setIsLoading(false);
    }
    loadSession();
  }, []);

  return (
    <div className="flex flex-column justify-content-between min-h-screen surface-200">
      <section>
        <Header />
        <main className="flex flex-column p-3">
          {isLoading ? (
            <div className="flex w-full justify-content-center align-items-center">
              <ProgressSpinner />
            </div>
          ) : (
            <Outlet />
          )}
        </main>
      </section>
      <Footer />
    </div>
  );
};

export default PageLayout;
