import { Link } from "react-router-dom";
import isologotipo from "../../assets/svgs/isologotipo.svg";

export const Footer = () => {
  return (
    <footer className="bg-primary px-5 border-round-top-xl">
      <Link to="/" className="no-underline text-white">
        <img
          src={isologotipo}
          alt="isologotipo"
          style={{
            position: "relative",
            right: "70px",
            width: "330px",
          }}
        />
      </Link>
    </footer>
  );
};
