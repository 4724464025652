import { UserForm } from "../../components/Forms/UserForm";

export const NewUser = () => {
  return (
    <div className="p-card gap-4  p-3">
      <h1 className="text-center text-3xl">Crear usuario ficticio</h1>
      <UserForm />
    </div>
  );
};
