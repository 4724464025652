/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { useGetEventTypesQuery } from "../../store/services/events-types";
import { limitsTypes } from "../../utils/constants/forms-constants";

interface EventFiltersProps {
  eventId?: number | undefined;
  setEventId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  startDate?: string;
  setStartDate?: React.Dispatch<React.SetStateAction<string | undefined>>;
  eventName?: string;
  setEventName?: React.Dispatch<React.SetStateAction<string>>;
  eventTypeId?: number | undefined;
  setEventTypeId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  eventSubTypeId?: number | undefined;
  setEventSubTypeId?: React.Dispatch<React.SetStateAction<number | undefined>>;
  limit?: number;
  setLimit?: React.Dispatch<React.SetStateAction<number>>;
}

export const EventFilters = ({
  eventId,
  setEventId = () => {},
  eventName,
  setEventName = () => {},
  startDate,
  setStartDate = () => {},
  eventTypeId,
  setEventTypeId = () => {},
  eventSubTypeId,
  setEventSubTypeId = () => {},
  limit,
  setLimit = () => {},
}: EventFiltersProps) => {
  const { data: dataEventsTypes, isLoading: isLoadingEventTypes } =
    useGetEventTypesQuery();

  const handleType =
    (name: "type" | "sub" | "startDate" | "limit") =>
    ({ value }: DropdownChangeEvent) => {
      if (name === "type") setEventTypeId(value);
      if (name === "sub") setEventSubTypeId(value);
      if (name === "startDate") setStartDate(value.toString());
      if (name === "limit") setLimit(value);
    };

  const handleString =
    (name: "startDate" | "eventName") =>
    ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      if (name === "startDate") setStartDate(currentTarget.value);
      if (name === "eventName") setEventName(currentTarget.value);
    };

  const clearFilter =
    (
      filter: "type" | "sub" | "startDate" | "eventName" | "eventId" | "limit"
    ) =>
    () => {
      if (filter === "type") {
        setEventTypeId(undefined);
        setEventSubTypeId(undefined);
      }
      if (filter === "limit") setLimit(10);
      if (filter === "sub") setEventSubTypeId(undefined);
      if (filter === "startDate") setStartDate(undefined);
      if (filter === "eventName") setEventName("");
      if (filter === "eventId") setEventId(undefined);
    };

  const handleEventId = ({ value }: InputNumberChangeEvent) =>
    setEventId(value || undefined);

  useEffect(() => {
    setEventSubTypeId(undefined);
  }, [eventTypeId]);

  return (
    <div className="grid">
      <div className="col-12 sm:col-8  p-inputgroup my-3">
        <span className="p-float-label lg:w-6">
          <InputText
            id="eventName"
            name="eventName"
            onChange={handleString("eventName")}
            value={eventName}
            // disabled={isLoadingCreate}
          />
          <label htmlFor="eventName">Nombre</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={clearFilter("eventName")}
        />
      </div>
      <div className="col-12 sm:col-4 p-inputgroup my-3 flex justify-content-end">
        <span className="p-float-label lg:w-5 ">
          <Dropdown
            id="limit"
            name="limit"
            onChange={handleType("limit")}
            value={limit}
            optionLabel="name"
            optionValue="value"
            options={limitsTypes}
            loading={isLoadingEventTypes}
            // disabled={isLoadingCreate}
          />
          <label htmlFor="limit">Límite</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={clearFilter("limit")}
        />
      </div>
      <div className="col-12 sm:col-6 lg:col-3 p-inputgroup">
        <span className="p-float-label">
          <Dropdown
            id="eventTypeId"
            name="eventTypeId"
            onChange={handleType("type")}
            value={eventTypeId}
            optionLabel="eventTypeName"
            optionValue="eventTypeId"
            options={dataEventsTypes}
            loading={isLoadingEventTypes}
            // disabled={isLoadingCreate}
          />
          <label htmlFor="eventTypeId">Tipo</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={clearFilter("type")}
        />
      </div>
      <div className="col-12 sm:col-6  lg:col-3 p-inputgroup">
        <span className="p-float-label">
          <Dropdown
            id="eventSubTypeId"
            name="eventSubTypeId"
            onChange={handleType("sub")}
            value={eventSubTypeId}
            optionLabel="eventSubTypeName"
            optionValue="eventSubTypeId"
            options={[
              ...(dataEventsTypes?.find(
                (item) => item.eventTypeId === eventTypeId
              )?.subTypes || []),
            ]}
            emptyMessage="Debe seleccionar un tipo"
            // disabled={isLoadingCreate}
          />
          <label htmlFor="eventSubTypeId">Sub-Tipo</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={clearFilter("sub")}
        />
      </div>
      <div className="col-12 sm:col-6 lg:col-3 p-inputgroup ">
        <span className="p-float-label">
          <Calendar
            className="w-full"
            id="startDate"
            name="startDate"
            dateFormat="dd/mm/yy"
            onChange={handleType("startDate")}
            value={startDate ? new Date(startDate) : undefined}
            hourFormat="24"
            hideOnDateTimeSelect
          />
          <label htmlFor="startDate">Fecha</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={clearFilter("startDate")}
        />
      </div>
      <div className="col-12 sm:col-6 lg:col-3 p-inputgroup ">
        <span className="p-float-label">
          <InputNumber
            id="eventId"
            name="eventId"
            onChange={handleEventId}
            value={eventId}
          />
          <label htmlFor="eventId">ID #</label>
        </span>
        <Button
          icon="pi pi-times"
          className="p-button-danger"
          onClick={clearFilter("eventId")}
        />
      </div>
    </div>
  );
};
